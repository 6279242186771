.title-nav-container {
  width: 100%;
  top: 43%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  cursor: url("../../public/brush-cursor-disabled.png"), pointer;

  .title {
    font-family: "Montserrat", "Verdana", Verdana, monospace;
    font-weight: 400;
    // font-size: clamp(44px, 9vw, 64px);
    font-size: clamp(44px, 9vw, 66px);
    margin: 10px 0 10px 0;
    text-shadow: 4px 3px 8px rgba(141, 141, 141, 0.837);
    transition: text-shadow 300ms ease;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: url("../../public/eraser.png"), pointer;

    &:hover {
      text-shadow: 6px 5px 12px rgba(167, 167, 167, 0.928);
    }
  }

  .navigate {
    margin: 2vw 0 10px 0;
    display: flex;
    justify-content: space-evenly;
    width: 84%;
    max-width: 625px;
    scale: 110%;
  }
}

/////////       Modal Window Container      /////////

.window {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #08162d7b;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

////
.modal-stain {
  position: fixed;
  width: 88%;
  max-width: 1000px;
  height: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.light-border {
    pointer-events: none;
    background: rgba(41, 57, 117, 0.52);
    border: 10px solid rgba(41, 57, 117, 0.107);
    border-radius: 10px;
    transition: opacity 1.2s ease-out;
    filter: blur(0.5px);
  }
  &.flash {
    pointer-events: none;
    z-index: 0;
    background: #e9f8ff;
    border: 1px solid #1e3352;
    border-radius: 10px;
    transition: opacity 125ms ease-out;
  }
  &.heavy-border {
    pointer-events: none;
    background: #3f150057;
    border-radius: 10px;
    border: 6px solid #0042a5;
    transition: opacity 1.9s ease-out;
    filter: blur(0.5px);
  }
}

#draw-canvas {
  // might interfere w touch draw
  // or buttons
  touch-action: none;

  margin: 0;
  inset: 0;
  display: block;
  position: fixed;
  cursor: url("../../public/brush-cursor.png"), pointer;
}

@media only screen and (max-height: 844px) {
  .title-nav-container {
    top: 40%;
  }
}

@media only screen and (max-width: 600px) {
  .modal-stain {
    .border,
    .flash {
      width: 86%;
    }
  }
}

@media only screen and (max-width: 470px) {
  .title-nav-container .navigate {
    scale: 90%;
    width: 95%;
  }
}

.btn-main {
  height: 42px;
  width: 37%;
  max-width: 103px;
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  margin: 5px;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

#projects {
  transform: perspective(45px) rotateX(4deg) rotateY(2deg);
}

#bio {
  .front,
  .edge {
    filter: brightness(89%);
  }
  transform: perspective(30px) rotateX(4deg) scale(0.96);
}
#contact {
  transform: perspective(45px) rotateX(9deg) rotateY(-3deg);
}

.shadow {
  height: 100%;
  width: 98%;
  position: absolute;
  top: 0;
  left: 1px;
  border-radius: 1px;
  background-color: rgba(176, 176, 176, 0.769);
  filter: blur(0.1px);
  border-top-left-radius: 9px;
  border-top-right-radius: 5px;
  transform: translateY(1.5px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1),
    filter 600ms ease-out;
  will-change: transform;
}

.edge {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1px;
  background: #151e26;
  background: linear-gradient(
    to left,
    #2a3037 0%,
    #0f161c 8%,
    #292a2e 92%,
    #4f5255 100%
  );
  border-top-left-radius: 9px;
  border-top-right-radius: 5px;
  transition: opacity 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.front {
  height: 100%;
  width: 100%;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 12px 13px 13px 12px;
  border-radius: 1px;
  background: rgb(78, 80, 84);
  background: linear-gradient(
    90deg,
    rgb(96, 98, 102) 0%,
    rgb(40, 55, 77) 3%,
    rgb(24, 40, 64) 11%,
    rgb(9, 19, 35) 80%
  );
  border-top-left-radius: 9px;
  border-top-right-radius: 5px;
  border-left: 6px solid #686b70;
  border-right: 5px solid #151e26;
  border-bottom: 4px solid #262c32;

  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1),
    filter 800ms ease-out;
  will-change: transform;
}

.btn-main:hover {
  filter: brightness(130%);
}

.btn-main:hover .front {
  filter: saturate(123%);
  transform: translateY(-9px);
  transition: transform 175ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.btn-main:hover .edge {
  opacity: 0.95;
  transition: opacity 175ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.btn-main:active .front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.btn-main:hover .shadow {
  transform: translateY(2.5px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  background: rgba(177, 177, 177, 0.895);
  background: linear-gradient(
    90deg,
    rgba(97, 112, 134, 1) 10%,
    rgba(77, 92, 114, 1) 59%,
    rgba(141, 152, 164, 1) 96%,
    rgba(155, 155, 155, 0.8284437068994495) 100%
  );

  filter: blur(0.2px);
}

.btn-main:active .shadow {
  transform: translateY(1.5px);
  transition: transform 34ms;
  background-color: rgba(65, 65, 65, 0.912);
}

.btn-main:focus:not(:focus-visible) {
  outline: none;
}

.carousel-container {
  width: 40%;
  height: 75%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.carousel-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
}

.carousel-content-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.carousel-content {
  width: 100%;
  display: flex;
  align-items: center;
  /* hide scrollbar in IE and Edge */
  -ms-overflow-style: none;
  /* hide scrollbar in Firefox */
  scrollbar-width: none;
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

///////////////
/// Buttons

.carousel-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 80%;

  .left-arrow,
  .right-arrow {
    z-index: 1;
    width: min(20vw, 40rem);
    max-width: 140px;
    height: 20px;
    border-radius: 24px;
    background-color: #05090f00;
    border: 1px solid #0e203c00;
    margin: -5px 0px 3px 0px;
    cursor: pointer;

    img {
      width: 100%;
      height: 55px;
    }
  }

  .left-arrow img {
    filter: drop-shadow(9px 5px 1.5px #121f38);
    transition: opacity 1.5s ease 1s, filter 250ms ease-out;
    // transition: filter 250ms ease-out;
    &:hover {
      filter: drop-shadow(-19px 5px 0.5px #121f38) brightness(125%);
    }
    &:active {
      filter: invert(25%);
    }
  }

  .right-arrow img {
    filter: drop-shadow(-9px 5px 1.5px #121f38);
    transition: opacity 1.5s ease 1s, filter 250ms ease-out;
    // transition: filter 250ms ease-out;
    &:hover {
      filter: drop-shadow(19px 5px 0.5px #121f38) brightness(125%);
    }
    &:active {
      filter: invert(25%);
    }
  }
}
.left-arrow:active .carousel-content {
  opacity: 0;
}

#carousel-spinner {
  position: relative;
  left: 40%;
}

@media only screen and (max-width: 1001px) {
  .carousel-container {
    width: 50%;
  }
}
// TODO: use variable to set button height with vh
@media only screen and (max-height: 1024px) {
  .carousel-buttons {
    top: 90%;
  }
}
@media only screen and (max-height: 754px) {
  .carousel-buttons {
    top: 100%;
  }
}

@media only screen and (max-height: 900px) and (max-width: 450px) {
  .carousel-buttons {
    top: 79%;
  }
}
@media only screen and (max-height: 750px) and (max-width: 450px) {
  .carousel-container {
    margin-top: 5%;
  }
  .carousel-buttons {
    top: 85%;
  }
}

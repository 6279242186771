// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap');

* {
  font-family:  BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: #f4e5e0;
  box-sizing: border-box;
  margin: 0;
}

body {
  background-color: #05090f;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
a:focus,a:visited,a:active{
  outline: none;
}
* {
    -webkit-tap-highlight-color: transparent;
}
.passion-list {
  list-style: none;
  padding-left: 10px;
  border-left: 7px solid #3b5275;
  border-bottom-left-radius: 5px;
  margin-left: 35px;
}
.passion-list > li {
  padding: 10px 0 10px 0;
}
.extra-list {
  width: 100%;
  padding-bottom: 10px;
  list-style: none;
  a {
    width: 100%;
    text-decoration: none;
    pointer-events: none;
  }
}
.extra-list > li {
  padding: 3px 0 3px 0;
  width: 100%;
  position: relative;
  padding-left: 10px;
  margin-bottom: 5px;
  border-left: 7px solid #3b5275;
  border-radius: 3px;
  left: -12px;
  cursor: pointer;
  &:hover {
    background-color: #3e609132;
  }
  &:active +
  li {
    width: 100%
  }
  
}

.music {
  font-size: clamp(12px, 1.5vh, 22px);
  justify-content: space-evenly;
  height: 100%;
  background: none;
  h3 {
    font-weight: 400;
  }
}

.music-header {
  padding-bottom: 10px;
}

.music-bio {
  font-size: clamp(10px, 1.75vw, 17px);
  color: #a8b9d4;
  padding-top: 1.25vw;
  strong {
    color: inherit;
  }
}

#inner-link {
  width: fit-content;
  padding-left: 10px;
  pointer-events: auto;
  &:hover {
    color: #a8b9d4;
  }
}

#arrow-icon {
  margin-left: 15px;
  height: 15px;
  pointer-events: auto;
  &:hover ~ #inner-link {
    color: #a8b9d4;
  }
}

@media only screen and (max-height: 800px) {
  .music {
    overflow: scroll;
    // display: block;
  }
}

// iPhone SE
@media only screen and (max-width: 414px) and (max-height: 896px) {
  .extra-list {
    padding-bottom: 0px;
  }
}

.modal-main {
  background: #1e3352;
  border-radius: 10px;
  position: fixed;
  width: 88%;
  max-width: 1000px;
  height: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 300ms ease;

  .close-btn {
    background-color: transparent;
    border: none;
    position: fixed;
    top: -15px;
    right: -15px;
    padding: 25px 25px 25px 0px;
    cursor: pointer;
    img {
      max-width: 18px;
    }
    &:hover {
      filter: contrast(0.4);
    }
  }

  .github-icon {
    position: fixed;
    bottom: 10px;
    left: 12px;
    scale: 1.15;
    filter: contrast(1.15);
    cursor: pointer;
    transition: opacity 350ms ease-in;
    &:hover {
      filter: contrast(0.45);
    }
  }

  header {
    font-size: clamp(15px, 3.5vw, 22px);
    width: 40%;
    top: 8%;
    left: 8%;
    position: fixed;
    z-index: 5;
    h1 {
      font-family: "Montserrat", sans-serif, "Courier New", Courier, monospace;
    }
  }

  .tab-header {
    h1 {
      font-weight: 400;
    }
    a {
      font-family: "Montserrat", sans-serif, "Courier New", Courier, monospace;
      font-size: 95%;
      text-decoration: none;
      transition: color 150ms ease, text-shadow 650ms ease;
    }

    &:hover a {
      color: #ffffff;
      text-shadow: 4px 3px 8px #8f7069;
    }
  }
}

.inner-window {
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tab-description {
    background: none;
    font-size: clamp(12px, 3.1vw, 20px);
    width: 42%;
    height: 83%;
    max-width: 312px;
    overflow: auto;
    position: relative;
    top: 6%;
    left: 6%;
    padding-right: 4%;
    margin-right: 1%;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }

  .project-icon {
    object-fit: contain;
    width: 50%;
    max-height: 172px;
    padding-right: 10%;
  }

  .project-screenshot {
    max-height: 360px;
    object-fit: contain;
    width: 100%;
  }
}

.its-live {
  color: rgb(148, 201, 255);
}

///////////////////////////////////////////////
///////// Modal Nav

.modal-nav {
  border-radius: 10px;
  position: fixed;
  width: 100%;
  height: fit-content;
  top: -15px;
  left: -15px;
  display: flex;
  transition: opacity 350ms ease-in;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  button {
    color: #f4e5e094;
    background: rgba(78, 80, 84, 0.142);
    background: linear-gradient(
      90deg,
      rgba(78, 80, 84, 1) 0%,
      rgba(27, 38, 52, 1) 3%,
      rgba(18, 29, 47, 1) 11%,
      rgba(6, 12, 22, 0.144) 80%
    );
    border-top-left-radius: 9px;
    border-top-right-radius: 5px;
    border-top: 1px solid #2f3136;
    border-right: 3px solid #2b3850;
    border-bottom: 2px solid #0f1c28;
    border-left: 2px solid #686b70;
    height: 37px;
    width: 14.5%;
    max-width: 88px;
    padding-left: 10px;
    margin: 0 6px 3px 0;
    display: flex;
    align-items: center;
    transition: color 200ms ease;
    cursor: pointer;

    &:hover {
      background: rgba(78, 80, 84, 0.142);
      background: linear-gradient(
        90deg,
        rgba(78, 80, 84, 1) 0%,
        rgba(27, 38, 52, 1) 3%,
        rgba(18, 29, 47, 1) 11%,
        rgba(34, 50, 78, 0.204) 80%
      );
      color: #f4e5e0;

      .tab-icon {
        opacity: 1;
      }
    }

    &:active {
      background: rgba(78, 80, 84, 0.142);
      background: linear-gradient(
        90deg,
        rgba(78, 80, 84, 1) 0%,
        rgba(27, 38, 52, 1) 3%,
        rgb(24, 37, 59) 11%,
        rgba(58, 82, 126, 0.286) 80%
      );
    }
  }

  .tab-icon {
    transition: opacity 200ms ease;
    height: 24px;
    opacity: 0.6;
  }
}

///////////////////////////////////////////////

.home-inner-window {
  width: 60%;
  height: 75%;
  display: flex;
  justify-content: center;
  z-index: 100;
}

.main-description {
  width: 100%;

  font-size: clamp(14px, 2.5vw, 20px);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.project-stack-list {
  padding-left: 20px;
  list-style: square;
  li {
    padding-top: 3px;
  }
  &.sub {
    font-size: 90%;
  }
}

///////////////////////////////////////////////
///////////////////     CONTACT FORM

.contact-form {
  background-color: #08142a7d;
  border-radius: 10px;
  min-height: 285px;
  height: fit-content;
  width: 89%;
  min-width: 233px;
  max-width: 330px;
  padding: 19px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  label {
    color: #f4e5e0c0;
    margin-bottom: 5px;
  }

  textarea {
    height: 11vh;
    font-size: 16px;
    border-color: #535353;
  }

  input,
  textarea {
    background-color: #0f1926;
    color: #f4e5e0cd;
    margin-bottom: 12px;
    resize: vertical;

    &:focus {
      outline: none;
    }
  }

  input[type="text"],
  input[type="email"] {
    font-size: 16px;
    height: 23px;
    border-top: 2px solid #17202b;
    border-right: 2px solid #3a4149;
    border-bottom: 2px solid #556272;
    border-left: 2px solid #2a3139;
  }

  input[type="submit"] {
    color: #f4e5e0c9;
    height: 11%;
    min-height: 26px;
    font-weight: 600;
    padding-bottom: 2px;
    border-top: 2px solid #556272;
    border-right: 2px solid #3a4149;
    border-bottom: 2px solid #17202b;
    border-left: 2px solid #2a3139;
    cursor: pointer;
    &:hover {
      background-color: #001128;
    }
    &:active {
      background-color: #112037;
    }
  }
}

#contact-me {
  visibility: hidden;
}

////////////////////////////////////////////////////

@media only screen and (max-width: 600px) {
  .inner-window {
    width: 88%;
  }
}

@media only screen and (max-width: 400px) {
  .modal-nav {
    left: -10px;
    width: 97%;
  }
}

@media only screen and (max-width: 775px) {
  .modal-main {
    .contact-header {
      visibility: hidden;
    }
    #contact-me {
      visibility: visible;
    }
  }
}

@media only screen and (min-width: 800px) and (min-height: 900px) {
  .inner-window .tab-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: inherit;
  }
}

// iPhone SE
@media only screen and (max-width: 375px) and (max-height: 667px) {
  .inner-window .tab-description {
    width: 43%;
    padding-right: 3%;
    padding-top: 2px;
  }
}

.card {
  position: fixed;
  width: 105%;
  max-width: 700px;
  height: fit-content;
  top: 50.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  z-index: 100;
  box-shadow: -18px -12px 25px #061122;
  transition: box-shadow 400ms ease;
  &:hover {
    box-shadow: -28px -20px 40px #03080f;
  }
}

.rooftop-card {
  position: fixed;
  width: 100%;
  max-width: 450px;
  height: fit-content;
  top: 250%;
  left: 55%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  z-index: 100;
  box-shadow: -18px -12px 25px #061122;
  transition: box-shadow 400ms ease;
  &:hover {
    box-shadow: -38px -30px 40px #03080f;
  }
}

.inner-card-link {
  height: fit-content;
  width: 100%;
  display: flex;
  &:hover {
    text-decoration: underline;
  }
}

.card-body {
  text-align: center;
  height: 7%;
  padding: 10px 0 10px 0;
}

.link-with-preview {
  width: 95%;
  padding-bottom: 4px;
  padding-left: 5px;
  border-left: 7px solid #3b5275;
  border-radius: 3px;
  left: -3px;
  position: relative;
  &:hover {
    // background-color: hsla(215, 40%, 41%, 0.196);
    background: rgb(62, 97, 145);
    background: linear-gradient(
      0deg,
      rgba(62, 97, 145, 0.19) 41%,
      rgba(62, 97, 145, 0.072) 75%,
      rgba(62, 97, 145, 0.05) 95%
    );
    box-shadow: 6px 0px 0px #3b52757c;
  }
}

.rooftop-with-preview {
  font-size: 185%;
  font-weight: 800;
  position: relative;
  width: 100%;
  transform: translate(-0.5%, 66%);
  border-radius: 3px;
  z-index: 100;
  cursor: pointer;
  &:hover {
    // background-color: hsla(215, 40%, 41%, 0.09);
    background: rgb(62, 97, 145);
    background: linear-gradient(
      180deg,
      rgba(62, 97, 145, 0.14) 29%,
      rgba(62, 97, 145, 0.025) 74%,
      rgba(62, 97, 145, 0) 92%
    );
    box-shadow: 6px 0px 0px #3b527566;
  }
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 7px;
  cursor: pointer;
  &:active {
    cursor: grabbing;
  }
}
.rooftop-card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 7px;
  cursor: pointer;
}

.card-title {
  font-size: 14px;
  cursor: pointer;
}

.link-close-btn {
  background-color: transparent;
  border: none;
  top: 10px;
  right: 5px;
  cursor: pointer;

  img {
    max-width: 25px;
  }
}

.transform-none {
  transform: none;
}

.boxshadow-none {
  width: 45px;
  font-size: 12px;
  font-weight: 100;
  box-shadow: none;
  box-shadow: none;
  position: fixed;
  &.rooftop-card {
    left: 53.5%;
  }
  &:hover {
    box-shadow: none;
  }
}

@media only screen and (max-height: 1000px) {
  .card {
    max-width: 75vh;
  }
  .rooftop-card {
    max-width: 45vh;
  }
}
@media only screen and (max-width: 400px) {
  .rooftop-with-preview {
    transform: translate(-0.25%, 66%);
  }
}
